<template>
  <div class="auth-wrapper auth-v2">

    <!-- /Login-->
    <b-row
      v-if="isChangeView"
      class="auth-inner m-0"
    >
      <span class="text-white title-login">
        Sở Lao Động - Thương Binh và Xã Hội Tỉnh {{ province }}
      </span>
      <span class="text-white title-login-small">
        Quản lý an toàn lao động
      </span>

      <!-- Brand logo-->
      <div @click="goToHome">
        <div class="brand-logo logo-home">
          <b-img
            class="w-100"
            :src="sideImglogo"
          />
        </div>
        <!-- <vuexy-logo
          class="brand-logo logo-home "
        /> -->
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex theme-login w-100"
        :style="{background: `url(${sideImg})`}"
      >
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h3"
          >
            Hệ thống ATLĐ - Sở LĐTBXH {{ province }}
          </b-card-title>
          <b-card-text class="mb-2">
            Vui lòng đăng nhập tài khoản của bạn và quản trị thông tin
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Tên đăng nhập"
                label-for="login-email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  name="login-email"
                  placeholder="john@example.com"
                />
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Mật khẩu</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Quên mật khẩu?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group v-if="!loading">
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Ghi nhớ
                </b-form-checkbox>
              </b-form-group>

              <div class="loading">
                <b-spinner
                  v-if="loading"
                  variant="primary"
                  class="align-seft-center"
                />

                <!-- submit buttons -->
                <b-button
                  v-else
                  type="submit"
                  variant="primary"
                  block
                >
                  Đăng nhập
                </b-button>
              </div>

            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Bạn là doanh nghiệp? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Tạo mới tài khoản</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>

    </b-row>

    <!-- Thay đổi mật khẩu mặc định-->
    <b-row
      v-if="!isChangeView"
      class="auth-inner m-0"
    >

      <!-- Brand logo-->
      <!-- <vuexy-logo class="brand-logo" /> -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex theme-login w-100"
        :style="{background: `url(${sideImg})`}"
      >
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <h4 class="mb-2">
            Đặt lại mật khẩu
          </h4>
          <span
            v-if="!passwordExpired"
            class="d-flex flex-column mb-2"
          >
            <span>Mật khẩu hiện tại của bạn là mặc định</span>
            <span>Vui lòng tạo lại mật khẩu đăng nhập</span>
          </span>
          <span
            v-if="passwordExpired"
            class="d-flex flex-column mb-2"
          >
            <span>Mật khẩu hiện tại của bạn đã hết hạn</span>
            <span>Vui lòng tạo lại mật khẩu đăng nhập</span>
          </span>
          <!-- form -->
          <validation-observer ref="rule">
            <b-form class="content-form">
              <b-form-group>
                <label for="InputHelp">Mật khẩu mới</label>
                <validation-provider
                  #default="{ errors }"
                  name="newPassword"
                  rules="required|min:6"
                  :custom-messages="customPassword"
                >
                  <b-form-input
                    id="InputHelp"
                    v-model="dataInput.newPassword"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <!-- </div> -->
              </b-form-group>
              <b-form-group>
                <label for="InputHelp">Xác nhận lại mật khẩu</label>
                <validation-provider
                  #default="{ errors }"
                  name="confirmNewPassword"
                  rules="required"
                  :custom-messages="customRepassword"
                >
                  <b-form-input
                    id="InputHelp"
                    v-model="dataInput.confirmNewPassword"
                    :state="errors.length > 0 ? false:null"
                    @keyup="checkPassword"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="showRepeatPass"
                    class="text-danger"
                  >Mật khẩu nhập lại không đúng
                  </small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
          <b-button
            variant="primary"
            block
            @click="handleReissuePassword"
          >
            Đặt mật khẩu mới
          </b-button>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import $ from 'jquery'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BSpinner, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { required, email, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import businessMenu from '@/navigation/vertical/business-menu'
import departmentMenu from '@/navigation/vertical/department-menu'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import storeDefault from '@/home/store'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BSpinner,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      min,
      loading: false,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/IMG3.png'),
      roleUser: null,
      cIp: '',
      sideImglogo: storeDefault.sideImglogo,
      province: storeDefault.province,
      changePassword: null,
      isChangeView: true,
      customPassword: {
        required: 'Mật khẩu là bắt buộc',
        min: 'Mật khẩu ít nhất 6 ký tự',
      },
      customRepassword: {
        required: 'Mật khẩu nhập lại là bắt buộc',
      },
      dataInput: {
        userId: '',
        isChangePassword: true,
        confirmNewPassword: '',
        newPassword: '',
      },
      showRepeatPass: false,
      emailUserId: '',
      passwordExpired: true,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/IMG3.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },

  created() {
    const token = localStorage.getItem('accessToken')
    if (token) {
      if (localStorage.getItem('userData').roleIdentity === 'DepartmentOfEducation') {
        this.$router.push({ name: 'dashboard-ecommerce' }).catch(() => {})
      } else {
        this.$router.push({ name: 'business-add-orther' }).catch(() => {})
      }
    }
  },
  methods: {

    async login() {
      const success = await this.$refs.loginForm.validate()
      if (success) {
        this.loading = true
        $.ajax({
          url: 'https://api.ipify.org/',
        }).done(async data => {
          this.cIp = data
          const dataAuthen = {
            userName: this.userEmail,
            cIp: this.cIp,
          }
          localStorage.setItem('authen_two_factor', JSON.stringify(dataAuthen))
          const response = await useJwt.login({
            userName: this.userEmail,
            password: this.password,
            cIp: this.cIp,
          })
          if (response?.status === 200) {
            const token = response.data?.data || ''
            if (token) {
              localStorage.setItem(jwtDefaultConfig.storageTokenKeyName, `Bearer ${token}`)
            }
            await this.accountInfo()
            this.changePassword = JSON.parse(localStorage.getItem('userData')).isChangePassword
            this.passwordExpired = JSON.parse(localStorage.getItem('userData')).isPasswordExpired
            this.dataInput.userId = JSON.parse(localStorage.getItem('userData')).userId
            this.dataInput.isChangePassword = JSON.parse(localStorage.getItem('userData')).isChangePassword
            if (this.changePassword === false || (this.passwordExpired === true && this.changePassword === true)) {
              this.loading = false
              this.isChangeView = false
              this.password = ''
            } else {
              this.$ability.update([{ action: 'manage', subject: 'all' }])
              const urlParams = new URLSearchParams(window.location.search)
              if (this.roleUser === 'DepartmentOfEducation') {
                this.$router.push(urlParams.has('redirect') ? urlParams.get('redirect') : { name: 'dashboard-ecommerce' }).catch(() => {})
              } else if (this.roleUser === 'EducationDepartment') {
                this.$router.push(urlParams.has('redirect') ? urlParams.get('redirect') : { name: 'infomation-unit-education' }).catch(() => {})
              } else {
                this.$router.push(urlParams.has('redirect') ? urlParams.get('redirect') : { name: 'business-add-orther' }).catch(() => {})
              }
              this.loading = false
            }
          } else if (response?.data?.errors?.length > 0) {
            if (response.data?.errors[0] === 'Vui lòng xác thực đăng nhập') {
              this.$root.$bvToast.toast(response.data?.errors[0], {
                title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
              })
              this.$router.push({ name: 'authen-two-factor' })
            } else {
              this.$root.$bvToast.toast(response.data?.errors[0], {
                title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
              })
            }
            this.loading = false
          }
        })
      }
    },
    // Lấy thông tin người dùng
    async accountInfo() {
      const { data } = await useJwt.accountInfo()
      if (data) {
        const userData = {
          ...data,
          ability: [{ action: 'manage', subject: 'all' }],
        }
        localStorage.setItem(jwtDefaultConfig.userData, JSON.stringify(userData))
        if (data?.roleIdentity) {
          this.roleUser = data?.roleIdentity
        }

        const dataMenu = await this.$store.dispatch('verticalMenu/getMenuByRole')
        localStorage.setItem('menuItems', JSON.stringify(dataMenu?.data))
        this.$store.commit('verticalMenu/UPDATE_MENU_ITEMS', dataMenu?.data)

        if (dataMenu?.data) {
          localStorage.setItem('menuItems', JSON.stringify(dataMenu?.data))
          this.$store.commit('verticalMenu/UPDATE_MENU_ITEMS', dataMenu?.data)
        } else {
          switch (data.roleIdentity.toUpperCase()) {
            case 'DEPARTMENTOFEDUCATION':
              localStorage.setItem('menuItems', JSON.stringify(departmentMenu))
              this.$store.commit('verticalMenu/UPDATE_MENU_ITEMS', departmentMenu)
              break
            case 'BUSSINESS':
              localStorage.setItem('menuItems', JSON.stringify(businessMenu))
              this.$store.commit('verticalMenu/UPDATE_MENU_ITEMS', businessMenu)
              break
            default:
              break
          }
        }
      }
      // const data = await this.$store.dispatch('users/accountInfo')
    },
    goToHome() {
      this.$router.push({ name: 'home', params: { check: true } })
    },

    async handleReissuePassword(bvModalEvt) {
      bvModalEvt.preventDefault()
      const model = {
        ...this.dataInput,
        isChangePassword: true,
      }
      this.$refs.rule.validate().then(async success => {
        if (success) {
          await axiosApiInstance.post('Account/reissue-password', model).then(res => {
            if (res.status === 200) {
              this.$hideAllPageLoading()
              this.$bvToast.toast('Cập nhật thành công', {
                title: 'Thông báo',
                variant: 'success',
                toaster: this.$toastPosition,
                solid: true,
              })
              this.isChangeView = true
              this.password = this.dataInput.newPassword
            }
          }).catch(e => {
            this.$bvToast.toast(e.response.data?.errors.ConfirmNewPassword[0], {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          })
        }
      })
    },
    checkPassword() {
      if (this.dataInput.newPassword !== this.dataInput.confirmNewPassword && this.dataInput.newPassword !== '') {
        this.showRepeatPass = true
      } else {
        this.showRepeatPass = false
      }
      if (this.dataInput.confirmNewPassword === '') {
        this.showRepeatPass = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.logo-home {
  cursor: pointer;
}
.theme-login {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.loading {
  text-align: center;
}

.title-login {
  position: absolute;
  z-index: 1;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  top: 3rem;
  left: 5rem;
  width: 40%;
}

.title-login-small {
  position: absolute;
  z-index: 1;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  top: 9.5rem;
  left: 5rem;
}

@media (max-width: 1300px) {
  .title-login {
    width: 60%;
  }
}

@media (max-width: 900px) {
  .title-login {
    display: none;
  }
  .title-login-small {
    display: none;
  }
}
</style>
